.thumbnail {
    margin: 3px;
    width: 32px;
    height: 32px;
}

.thumbnail:hover {
    cursor: pointer;
}

.img-thumbnail {
    padding: 0px !important;
}

.thumbnail.empty {
    border-radius: 6px;
    background-color: var(--bgLightGrey);
    text-align: center;
}

.thumbnail_list_container {
    display: flex;
    margin-top: 9px;
    flex-wrap: wrap;
    float: left;
    padding-right: 5px;
}

.thumbnail_count_text {
    font-weight: 500;
    font-size: 10px;
    color: var(--unselectedGrey);
}

.thumbnail_fetching_container {
    display: flex;
    flex-wrap: wrap;
    float: left;
    margin-top: 9px;
    color: var(--unselectedGrey);
    font-size: 10px;
}

.thumbnail_dialog_content {
    height: 75%;
    max-height: 75%;
}

.pagination {
    z-index: 3;
}

.page-link {
    color: var(--darkGreen);
}

.page-item.active .page-link {
    background-color: var(--darkGreen);
    color: var(--white);
    border-color: var(--darkGreen);
}

.page-item.disabled .page-link {
    background-color: var(--disabledBgGrey);
    color: var(--disabledTextGrey);
    opacity: 0.7;
}

.page-item:hover .page-link:hover {
    cursor: pointer !important;
}

@import './Colors.css';

/* to load animation for a specific spinner */
@import '~spinners-react/lib/SpinnerCircularFixed.css';

.pointer:hover {
    cursor: pointer;
}

.footer {
    width: 100%;
    background-color: transparent;
    position: fixed;
    bottom: 0px;
    height: 75px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: rgba(0, 96, 100, 0.1);
    padding-right: 32px;
    padding-bottom: 32px;
}

.footer_add_incident_button {
    font-size: 18px;
    font-weight: bold;
    padding: 11px 22px 13px 26px;
    background-color: var(--darkGreen);
    color: var(--white);
    border-radius: 36px;
    border-color: transparent;
    text-decoration: none;
}

#session_expiry_button {
    font-size: 16px;
    font-weight: bold;
    background-color: var(--darkGreen);
    color: var(--white);
}

.footer_add_incident_button:hover {
    color: var(--white);
    background-color: var(--darkGreenHover);
    text-decoration: none;
    cursor: pointer;
}

.footer_button_arrow {
    margin-left: 18px;
}

.center_page {
    display: table-cell;
    flex: 1;
    vertical-align: middle;
    justify-content: center;
}

.center_page_parent {
    display: table;
    width: 100%;
    height: 90%;
    text-align: center;
}

html,
body {
    height: 100%;
}

#root {
    height: 100%;
}

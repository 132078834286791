.incident_table_container {
    display: flex;
    justify-content: center;
}

.incident_table_child {
    margin-top: 28px;
    padding: 0 18px;
    max-width: 1200px;
    width: 1200px;
}

.incident_table_light_text {
    color: var(--unselectedGrey);
    font-size: 16px;
    font-weight: normal;
}

.incident_table_strong_text {
    font-size: 20px;
    font-weight: bold;
    word-break: break-word;
}

.incident_table_status_badge {
    padding: 1px 13px 2px;
    line-height: 1.9;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    width: auto;
    text-align: center;
}

.incident_table_status_badge.light_blue {
    background-color: var(--textBlue);
    color: var(--beige);
}

.incident_table_status_badge.mini {
    width: auto;
    min-width: auto;
    font-size: 10px;
    margin: 0 3px;
    font-weight: 600;
    padding: 3px;
    height: auto;
}

.incident_table_status_badge.yellow {
    background-color: var(--bgMustardYellow);
    color: var(--textDarkYellow);
}

.incident_table_status_badge.blue {
    background-color: var(--bgMediumBlue);
    color: var(--textMediumBlue);
}

.incident_table_status_badge.green {
    background-color: var(--bgLightGreen);
    color: var(--textMediumGreen);
}

.incident_table_status_badge.gray {
    background-color: var(--bgLightGray);
    color: var(--textMediumVGray);
}

.incident_table_edit {
    background-color: rgba(0, 96, 100, 0.15);
    color: var(--darkGreen);
    font-size: 15px;
    font-weight: bold;
    height: 40px;
    width: 75px;
    margin: 5px;
    float: right;
    padding: 8px 16px 9px 17px;
    border-radius: 6px;
    border: none;
}

.incident_table_archive {
    background-color: rgba(0, 96, 100, 0.15);
    color: var(--darkGreen);
    font-size: 15px;
    font-weight: bold;
    height: 40px;
    width: 75px;
    margin: 5px;
    float: right;
    border-radius: 6px;
    border: none;
}

.incident_table_edit:hover {
    background-color: rgba(0, 96, 100, 0.35);
}

.incident_table_archive:hover {
    background-color: rgba(0, 96, 100, 0.35);
}

.incident_table_status_badge_container {
    margin-top: 7px;
    width: 160px;
}

.incident_table_status_header {
    color: var(--unselectedGrey);
    font-size: 16px;
    font-weight: normal;
}

.font-16 {
    font-size: 16px;
}

.bold {
    font-weight: bold;
}

.word_break {
    word-break: break-word;
}

.incident_table_med_text {
    font-size: 14px;
    color: var(--mediumText);
    display: -webkit-inline-box;
}

.incident_table_row {
    margin: 18px 0 0;
    border-radius: 6px;
    background-color: var(--white);
    box-shadow: 0 2px 10px 2px rgba(42, 42, 0, 0.1);
}

.incident_table_row.yellow {
    background-color: var(--beige);
}

td:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

td:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

table {
    border-collapse: separate !important;
    border-spacing: 0 18px;
}

.incident_table_classifications_badge {
    font-size: 10px;
    font-weight: 500;
    padding: 5px;
    color: var(--unselectedGrey);
    background-color: var(--bgLightGrey);
    border-radius: 3px;
    margin: 3px;
}

.incident_table_classifications_badge.injury,
.incident_table_classifications_badge.property_damage,
.incident_table_classifications_badge.near_miss,
.incident_table_classifications_badge.default {
    color: var(--white);
    background-color: var(--darkGreen);
}

.incident_table_classifications_badge.first_aid {
    color: var(--textGold);
    background-color: var(--bgMediumYellow);
}

.incident_table_classifications_badge.lost_time {
    background-color: var(--bgOrange);
    color: var(--textOrange);
}

.incident_table_date_container {
    min-width: 110px;
}

.incident_table_description_container {
    font-size: 10px;
    color: var(--unselectedGrey);
    margin-top: 12px;
    height: 32px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.incident_table_action_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.incident_table_no_records_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.incident_table_pagination_container {
    display: flex;
    justify-content: center;
}

.incident_table_icons {
    margin-right: 5px;
    color: rgba(0, 96, 100, 0.35);
}

.incident_table_user_none_added {
    font-style: italic;
    color: var(--mediumText);
}

.empty_table_column {
    width: 132px;
    height: 48px;
}

.sort_arrow {
    padding-left: 10px;
}

.sort_active_down {
    position: relative !important;
    left: -10px;
}

.dark_green {
    color: var(--darkGreen);
}

.light_gray {
    color: lightgray;
}

:root {
    --darkGreen: #006064;
    --bgLightRed: #ffe0e0;
    --bgFormsRed: #e73d4a;
    --textRed: #b40000;
    --bgLightBlue: #def8ff;
    --textBlue: #27a8cd;
    --white: #ffffff;
    --darkGreenHover: #003b3d;
    --brightRed: #ff0000;
    --unselectedGrey: #878787;
    --beige: #ffffee;
    --bgMustardYellow: #e9e974;
    --textDarkYellow: #414100;
    --textMediumBlue: #07587c;
    --bgMediumBlue: #d0ebf7;
    --bgLightGreen: #d7f7d0;
    --textMediumGreen: #147000;
    --bgLightGray: #e0e0eb;
    --textMediumGray: #9494b8;
    --mediumText: #666666;
    --bgLightGrey: #f1f1f1;
    --bgMediumYellow: #fff8cf;
    --textGold: #a18d1a;
    --bgOrange: #ffe4cf;
    --textOrange: #ba662c;
    --disabledBgGrey: #f4f6f6;
    --disabledTextGrey: #d5dbdb;
    --bgTorquoise: rgba(0, 96, 100, 0.15);
    --white: rgb(250, 250, 250);
}

.notification_container {
    margin: 10px;
}

.notification {
    font-weight: 500;
}

.notification.error {
    background-color: var(--bgFormsRed);
    color: var(--white);
}

.notification.default {
    background-color: var(--bgTorquoise);
    color: var(--darkGreen);
}

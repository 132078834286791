.bg-dark-green {
    background-color: var(--darkGreen);
}

.navbar {
    padding-top: 0px;
    padding-bottom: 0px;
}

.navbar_title {
    font-size: 20px;
    font-weight: 300;
    font-family: sans-serif;
    color: rgba(255, 255, 255, 1);
    align-items: center;
}

#navbar_menu_toggle {
    margin-left: 5px;
}

.offcanvas_user_info_container {
    padding: 7px;
}

.offcanvas_user_label {
    color: var(--unselectedGrey);
    font-size: 10px;
    margin: 0;
}

.offcanvas_user_value {
    color: var(--black);
    font-size: 16px;
}

.offcanvas_link_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    width: 100%;
}

.offcanvas_nav_link {
    font-size: 20px;
    color: var(--black);
    font-weight: 400;
    display: flex;
    align-items: center;
}

.offcanvas_nav_link.nav-link {
    padding: 7px;
}

.offcanvas_nav_link:hover {
    background-color: rgba(158, 158, 158, 0.2);
    color: var(--black);
}

.offcanvas_nav_link.logout {
    font-size: 18px;
    color: var(--black);
}

.offcanvas_footer {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.offcanvas_nav_link_icon {
    margin-right: 15px;
}

#offcanvas_navbar {
    width: 320px;
}

#offcanvas_navbar_header {
    background-color: var(--darkGreen);
    color: var(--white);
    min-height: 64px;
}

.navbar_menu_title {
    min-height: 64px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 20px;
}

.navbar-toggler {
    border: none !important;
}

.navbar-toggler:focus {
    color: transparent;
    box-shadow: transparent;
}

.version-number {
    float: right;
    padding: 5px;
}

#offcanvas_body {
    padding: 0;
}

.filtertabs_container {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}

.filtertab {
    min-height: 53px;
    min-width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.filtertab:hover {
    cursor: pointer;
}

.filtertab.selected {
    color: rgb(63, 81, 181);
}

.filtertab.selected > div.hr,
.filtertab:hover > div.hr {
    border-bottom: solid 2px var(--brightRed);
    width: 100%;
}

.filtertab.not_selected {
    color: var(--unselectedGrey);
}

.filtertab_badge {
    border-radius: 16px;
    height: 24px;
    width: 24px;
    margin: 0 0 0 7px;
    padding: 2px 8px 3px;
}

.filtertab_label {
    flex-grow: 0.5;
}
